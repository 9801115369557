.topMargin {
  margin-top: auto;
  margin-bottom: auto;
}

.marginLeft {
  margin-left: -5%;
}
.lblTotalPg {
  font-size: medium;
}
