.forbiddenScreen{
    flex-grow: 1;
    height:100%;
    width:100%;
    background-color: #385988;
}

.forbiddenOverlayContent{
    color: black;
    padding:20px;
}
