@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/table/lib/css/table.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

div .bp3-popover-content div ul.bp3-menu {
  overflow-y: auto;
  max-height: 300px;
}

.App-container {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: -apple-system, "Arial", "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue",
    "Icons16", sans-serif;
}

.App-link {
  color: #09d3ac;
}

div#mainHeader {
  margin-left: 50px;
}