.formContainer {
    color: #000;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    padding-bottom: 120px;
}

.fullWidthInput {
    width: 100%;
}

.errorText {
    color: #fe671f;
}

.inputSection {
    background-color: #ffffff;
    padding: 10px;
}

.card {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.saveChangesButton {
    position: fixed;
    bottom: 4%;
    right: 4%;
}

h5 {
    text-align: left;
}


h6 {
    text-align: left;
}

label {
    text-align: left;
}

input{
    color: black !important;
}

.fullscreenImageContainer{
    background-color: white;
    overflow: auto;
}
.closeButton{
    position: absolute;
    top: 2%;
    left: 2%;
}