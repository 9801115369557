.projectDetailsView{
    width: 100%;
    height: 100%;
}

.border{
    margin: 0;
    border-bottom-width: 2px;
    border-bottom-color: #385988;
}

.thumbnailContainer{
    background-color: #e3e3e3;
}

.button{
    background-color: #385988;
}

.buttonCard{
    background-color: #385988;
    padding: 0 0 0 15px;
}

.gridButton{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
}

.gridButton .bp3-button-text{
    width: 100%;
    height: 100%;
}

.buttonLabel{
    margin: 0 0 0 15px;
}

.listContainer{
    display:flex;
    flex-direction: column;
    max-width: 768px;
    width:100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.thumbnail{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
