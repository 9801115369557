.formContainer{
    color: #000;
    display:flex;
    flex-direction: column;
    max-width: 1200px;
    width:100%;
}

.fullWidthInput{
    width:100%;
}

.errorText{
    color: #fe671f;
}

.inputSection{
    background-color: #ffffff;
}

.card{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

}

.scrollView{
    overflow: auto;
    height: 500px;
    width: 50%;
}


