.headerContainer {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.headertitle {
    display: flex;
    color: black;
}

.pagerContainer {
    float: right;
}

.perPageSelect {
    border: none;
}

.searchInput {
    float: left;
}

.addNoteBtn {
    float: right;
}

.addNoteBtnTxt {
    text-decoration: initial !important;
    font-size: 14px !important;
}

.pageFooter {
    padding-top: 3px;
    color: black;
}

.perPageContainer {
    margin-left: 30rem;
    display: inline-block;
    font-size: 15px;
    color: black;
}

.submitBtn {
    width: 100px;
    min-height: 40px !important;
    min-width: 40px !important;
    font-size: 16px !important;
    padding: 5px 15px !important;
    margin: auto !important;
    margin-top: 10px !important;
    background-color: #f5f8fa !important;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)) !important;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1) !important;
    color: #182026 !important;
    border: none !important;
}

.submitBtn:hover {
    border: none !important;
}

.MuiOutlinedInput-root.Mui-focused {
    border-color: rgba(125, 188, 255, 0.6) !important;
}