.manageTasksView{
    width: 100%;
    height: 100%;
    color: black;
}
.fullscreenImageContainer{
    background-color: white;
    overflow: auto;
}
.closeButton{
    position: absolute;
    top: 2%;
    left: 2%;
}
.listContainer{
    color: #000;
    display:flex;
    flex-direction: column;
    max-width: 1536px;
    width:100%;
}
.tableContainer{
    border: 1px solid black;

  width: 50%
}
