.navigationContainer {
    flex-direction: row;
}

.navBar {
    min-width: 100vw;
    background-color: #385988;
    display: flex
}

.navBarSpacer {
    width: 100vw;
    z-index: -100;
}

.navBarLeft {
    flex: 1;
    justify-content: flex-start;
}

.navBarCenter {
    flex: 4;
    overflow: hidden;

}

.navBarCenterHeader {
    overflow: hidden;
    width: 100%;
}

.navBarRight {
    flex: 1;
    justify-content: flex-end;
}

.titleText {
    margin: 0;
    font-size: 16px !important;
}