.addressTextContainer{
    text-align: left;
    color: black;
}

.addressText{
    margin:0;
}

.icon{
    margin:10px;
}

.addressLink{
    font-size: 1.17em;
}
