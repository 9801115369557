.pickerCard{
    color: black;
    margin: 15px;
}

.pickerCardTitle{
    margin:0;
}

.assignedTasksContainer{
    padding: 10px;
}
.attachmentTile{
    border-style: none;
    border-width: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    margin-right: 25px;
  
}
.attachmentTileSelect{
    border-style: solid;
    border-width: 2;
    border-color: green;
    padding: 0;
    width: 100%;
    height: 100%;
    margin-right: 25px;
  
}
.iconColor{
    color: green
}
.thumbnailContainer{
    background-color: #e3e3e3;
}
.attachmentTile .bp3-button .bp3-button-text {
    flex: 1
}
.titleTag{
    position: absolute;
    bottom: -7px;
}

.fullscreenImageContainer{
    background-color: white;
    overflow: auto;
}
.closeButton{
    position: absolute;
    top: 2%;
    left: 2%;
}
.assignedTasksList{
    margin-top: 10px;
}

.fullWidthDivider{
    width: 100%;
    margin-bottom: 20px;
}

.numericInput{
    width: 100%;
}

.numericInput .bp3-input-group{
    width: 81%;
}

.taskText{
    text-align: center;
}
.noOverflow{
    overflow:hidden;
}

.listContainer{
    color: #000;
    display:flex;
    flex-direction: column;
    max-width: 1536px;
    width:100%;
}
