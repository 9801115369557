.listContainer{
    color: #000;
    display:flex;
    flex-direction: column;
    max-width: 1536px;
    width:100%;
}

.noAttachmentsText{
    text-align: center;
}

.myAttachmentsTitle{
    color: #000000;
}

.attachmentTile{
    border-style: none;
    border-width: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    /*margin-right: 25px;*/
}

.attachmentTile .bp3-button .bp3-button-text {
    flex: 1
}

.thumbnailContainer{
    background-color: #e3e3e3;
}

.badgeContainer{
    position:relative;
    left:-12.5px;
}

img{
    max-width:100%;
    max-height:100%;
}

.closeButton{
    position: absolute;
    top: 2%;
    left: 2%;
}

.fullscreenPicker{
    background-color: white;
}
.fileList{
    overflow: auto;
    max-height: 100%;
    width: 100%;
}

h6{
    text-align: center;
}

.fullscreenFile{
    background-color: white;
    overflow: auto;
}

.imagePreview{
    background-color: white;
    width:100%;
    height:100%;
    object-fit: cover;
}
