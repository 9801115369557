.pickerCard{
    color: black;
    margin: 15px;
}

.pickerCardTitle{
    margin:0;
}

.assignedTasksContainer{
    padding: 10px;
}

.assignedTasksList{
    margin-top: 10px;
}

.fullWidthDivider{
    width: 100%;
    margin-bottom: 20px;
}

.numericInput{
    width: 100%;
}

.numericInput .bp3-input-group{
    width: 81%;
}

.taskText{
    text-align: center;
}

.upDownArrow{
    margin-top: 12%;
}
