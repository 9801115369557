.teamViewContainer {
    color: black;
}

.teamCard {
    padding: 5px;
    width: 100%;
    text-align: left;
}

h6 {
    margin: 5px;
    text-align: left;
}

.save_btn {
    float: right;
}